import React, { useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import './SectionOne.css';
import video from '../../assets/models/scanupdate_BlackBG.mp4';
import vectorIcon from '../../assets/icons/Vector.svg';
import { useGSAP } from '@gsap/react';
import { useNavigate, useLocation } from 'react-router-dom';


gsap.registerPlugin(ScrollTrigger);

const smoothScrollTo = (id) => {
  const element = document.querySelector(id);
  if (element) {
    gsap.to(window, {
      duration: 2, // Duración en segundos, ajusta este valor para hacer el scroll más lento o más rápido
      scrollTo: {
        y: element,
        offsetY: 70 // Ajusta este valor si tienes un header fijo
      },
      ease: "power2.inOut" // Puedes cambiar la función de easing para ajustar la curva de animación
    });
  }
};

const SectionOne = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const sectionRef = useRef();
  const textBoxOne = useRef();
  const titleOneRef = useRef();
  const textOneRef = useRef();
  const textBoxTwo = useRef();
  const titleTwoRef = useRef();
  const textTwoRef = useRef();
  const videoRef = useRef();
  const buttonRef = useRef();

  

  useGSAP(() => {
    const mm = gsap.matchMedia();

    mm.add("(min-width: 550px)", () => {
      gsap.fromTo(videoRef.current,
        { x: '50vw' },
        {
          x: '0',
          scrollTrigger: {
            trigger: videoRef.current,
            start: 'top 60%',
            end: 'top 20%',
            scrub: true,
          }
        }
      );
    });

    mm.add("(max-width: 549px)", () => {
      gsap.fromTo(videoRef.current,
        { x: '100vw' },
        {
          x: '0',
          scrollTrigger: {
            trigger: videoRef.current,
            start: 'top 60%',
            end: 'top 20%',
            scrub: true,
          }
        }
      );
    });

    // Animaciones comunes para ambos tamaños de pantalla
    gsap.timeline({
      scrollTrigger: {
        trigger: titleOneRef.current,
        start: 'top 80%',
        end: 'top 20%',
        scrub: true
      }
    })
      .from(titleOneRef.current, { y: 50, opacity: 0 }, 0)
      .from(textOneRef.current, { y: 50, opacity: 0 }, 0)
      .from(buttonRef.current, { opacity: 0, scale: 0.6 }, 1);

    gsap.timeline({
      scrollTrigger: {
        trigger: titleTwoRef.current,
        start: 'top 90%',
        end: 'top 20%',
        scrub: true
      }
    })
      .from(titleTwoRef.current, { y: 50, opacity: 0 }, 0)
      .from(textTwoRef.current, { y: 50, opacity: 0 }, 0);

    return () => {
      mm.revert();
    };
  }, []);

  const handleNavClick = (id) => {
    if (location.pathname !== '/') {
      navigate('/');
      // Espera a que la navegación se complete antes de hacer scroll
      setTimeout(() => smoothScrollTo(id), 100);
    } else {
      smoothScrollTo(id);
    }
  };

  return (
    <section className="section-one" ref={sectionRef} id="section-one">
      <div className="max-w-1200">
        <div className="video-wrapper2">
          <video ref={videoRef} src={video} className="background-video" autoPlay muted playsInline></video>
        </div>
        <div className="content">
          <div className="text-box" ref={textBoxTwo}>
            <h1 ref={titleTwoRef} className="title-one">
              The Authenticity <span className="highlight">Problem</span>
            </h1>
            <p ref={textTwoRef} className="text-one">
              In a world flooded with imitations, the true value of authenticity has never been more critical.
            </p>
          </div>
          <div className="text-box" ref={textBoxOne}>
            <h1 ref={titleOneRef} className="title-two">
              A New Era of <span className="highlight">Ownership</span>
            </h1>
            <p ref={textOneRef} className="text-two">
              Discover Aurea, where the physical and digital converge to redefine what you own, authenticity is immutable and trust is absolute.
            </p>
            <a className="join-button" ref={buttonRef} href="#join-us" onClick={(e) => { e.preventDefault(); handleNavClick('#join-us'); }}>
              <p>Join Us</p>
              <img src={vectorIcon} alt="Scroll down arrow" className="vector-icon" />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SectionOne;
