import IntroForm from "../sections/IntroForm";
import IntroSection from "../sections/IntroSection";
import backgroundImageArtists from '../../assets/images/venus-3d-cut.png';
import CarrouselArtists from '../sections/CarrouselArtists';
import ContactFormArtists from '../forms/emailFormArtist';
import Subtitulo
 from '../text-components/SubtituloSeccion';
import BulletPoints from '../sections/BulletPoints';

import video from '../../assets/models/scanupdate_BlackBG.mp4';
import ParrafoNormal from "../text-components/parrafoNormal";


function Artists() {
  const bulletPoints = [
    'New Markets: Transform your art into a financial asset with global appeal.',
    'Liquidity: Unlock immediate funds through fractional ownership opportunities.',
    'Passive Income: Secure ongoing revenue from royalties on every resale.',
    'Everlasting Protection: Stay ahead in the art world with cutting-edge technology.'
  ];

  return (
    <main>
      <IntroForm title1="AUTHENTICATE" title2=" YOUR ART" backgroundImage={backgroundImageArtists} />
      <div className="black-background">
        <div className="spacer-60"></div>
        <Subtitulo texto="Turn Your Art into a Financial Asset with AUREA" />
        <CarrouselArtists />
        <div className="spacer-60"></div>
        <Subtitulo texto="Why Choose AUREA?" />
        <div className="spacer-30"></div>
        <BulletPoints items={bulletPoints} video={video}/>
        <div className="spacer-30"></div>
        <Subtitulo texto="Start Your Journey Today" />
        <div className="spacer-30"></div>
        <ParrafoNormal texto="Join AUREA and turn your art into a powerful financial asset. Access new markets, unlock liquidity, and earn passive income while securing your artistic legacy. Contact us to learn more and begin tokenizing your art today." />
        <div className="spacer-30"></div>
      </div>
      <ContactFormArtists/>
      <IntroSection />
    </main>
  );
}

export default Artists;
