import React, { useRef, useEffect } from 'react';
import videoAurea from '../../assets/video/AUREASOLUTIONS.mp4';
import './VideoAurea.css';
import posterFrame from '../../assets/images/Screenshot 2024-10-03 at 18.56.17.png';

const VideoComponent = () => {
  const videoRef = useRef(null);

  useEffect(() => {
    // Define the callback function for intersection observer
    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // If the video is in view, play the video
          videoRef.current.play();
        } else {
          // If the video is out of view, pause the video
          videoRef.current.pause();
        }
      });
    };

    // Create a new intersection observer
    const observer = new IntersectionObserver(handleIntersection, {
      threshold: 0.5, // Adjust as needed, 0.5 means 50% of the video should be visible
    });

    // Observe the video element
    if (videoRef.current) {
      observer.observe(videoRef.current);
    }

    // Cleanup observer on component unmount
    return () => {
      if (videoRef.current) {
        observer.unobserve(videoRef.current);
      }
    };
  }, []);

  return (
    <section className="video-section">
      <div className="video-container">
        <video
          ref={videoRef}
          playsInline
          preload="auto"
          controls
          poster={posterFrame}
          muted // Mute the video to allow autoplay in most browsers
        >
          <source src={videoAurea} type="video/mp4" />
          Tu navegador no soporta el elemento de video.
        </video>
      </div>
      <div className="spacer-60"></div>
    </section>
  );
};

export default VideoComponent;
