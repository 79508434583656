import React from 'react';
import './parrafoNormal.css';

const ParrafoNormal = ({ texto }) => {
  return (
    <div className="parrafo-normal">
      <div className="max-w-1200">
        <p className="parrafo-normal-text">{texto}</p>
      </div>
    </div>
  );
};

export default ParrafoNormal;