import React, { useState } from 'react';
import ContactForm from '../forms/emailForm';
import './LearnMore.css';

const faqs = [
  {
    question: '01. What is Aurea?',
    paragraphs: [
      `AUREA is a cutting-edge platform that leverages blockchain technology to authenticate and tokenize high-value assets like artworks and luxury items. By turning these physical assets into Non-Fungible Tokens (NFTs), AUREA provides a secure, transparent, and tamper-proof way to prove ownership and authenticity. Whether you're an investor, collector, or brand, AUREA enables you to access new markets, fractionalize ownership, and generate passive income through royalties—all while protecting your assets from counterfeiting and fraud.`
    ]
  },
  {
    question: '02. How does AUREA Work?',
    paragraphs: [
      `AUREA works by transforming physical assets, such as artwork or luxury items, into digital assets using blockchain technology. Here’s how the process works:`
    ],
    list: [
      {
        title: 'Tokenization',
        description: 'Each physical item is securely linked to a unique Non-Fungible Token (NFT), which acts as a digital certificate of authenticity and ownership.'
      },
      {
        title: 'NFC Integration',
        description: 'An NFC tag embedded in or attached to the item allows users to scan and verify the item’s authenticity through the AUREA app.'
      },
      {
        title: 'Fractional Ownership',
        description: 'The NFT can be divided into smaller ownership shares, allowing multiple investors to own a fraction of a high-value asset, such as a piece of art or a luxury product.'
      },
      {
        title: 'Marketplace',
        description: 'Once tokenized, the asset can be traded, sold, or resold on various digital marketplaces, providing liquidity and new investment opportunities.'
      },
      {
        title: 'Royalties',
        description: 'Brands or creators earn royalties each time the NFT is resold, creating a continuous revenue stream.'
      }
    ]
  },
  {
    question: '03. What is an NFT?',
    paragraphs: [
      `An NFT (Non-Fungible Token) is a unique digital asset stored on a blockchain, representing ownership or proof of authenticity for a specific item. Unlike cryptocurrencies, NFTs are not interchangeable, as each one is unique. In AUREA the NFTs act as Certificates of Authenticity and Ownership.`
    ]
  },
  {
    question: '04. What is NFC?',
    paragraphs: [
      `NFC (Near Field Communication) is a short-range wireless technology that allows devices to communicate with each other when they're close together, typically within a few centimeters. It’s commonly used in contactless payments, digital identification, and data exchange, as well as for applications like scanning tags for product authentication or security purposes.`,
      'In AUREA we use NTAG424 DNA whose chip is designed to support operations protected by AES-128 encryption, including a new SUN (Secure Unique NFC Message) authentication mechanism, as well as the protection of sensitive data with encrypted access permissions. These features enable advanced protection for products and content, along with unique and secure real-time user experiences.'
    ],
  },
  {
    question: '05. What does Tokenization mean?',
    paragraphs: [
      `Tokenization is the process of converting a physical or digital asset into a digital token on the blockchain. These tokens represent ownership or rights to the underlying asset. In the case of AUREA, luxury goods, art, and other high-value items are tokenized as NFTs (Non-Fungible Tokens), providing a secure and transparent way to verify authenticity, track ownership, and enable fractional ownership or trading in digital marketplaces.`
    ]
  },
  {
    question: '06. What type of assets can be protected by AUREA?',
    paragraphs: [
      `AUREA protects a wide range of assets from counterfeiting by creating secure digital records of authenticity and ownership. These assets include fine art, luxury goods (watches, jewelry, handbags), collectibles (sneakers, sports memorabilia), consumer goods (designer clothing, perfumes, electronics), antiques, and official merchandise like soccer jerseys. By using tokenization and blockchain technology, AUREA ensures that any valuable or frequently counterfeited item can be authenticated and verified.`
    ]
  },
  {
    question: '07. What are the benefits for artists?',
    list: [
      {
        title: 'Access to New Markets',
        description: 'By tokenizing their art, artists can reach a global audience where their work becomes a financial asset, attracting investors and collectors.'
      },
      {
        title: 'Liquidity through Fractional Ownership',
        description: 'Artists can sell fractions of their artwork, allowing more buyers to invest, providing immediate liquidity without selling the entire piece.'
      },
      {
        title: 'Passive Income from Royalties',
        description: 'Artists can earn ongoing royalties every time their tokenized artwork is resold.'
      },
      {
        title: 'Protection Against Counterfeiting',
        description: 'AUREA ensures authenticity by creating a secure, blockchain-based record of ownership, protecting the artist’s work from fraud.'
      },
      {
        title: 'Simple and Intuitive Process',
        description: 'Tokenizing art with AUREA is straightforward, allowing artists to easily transform their creations into NFTs.'
      }
    ]
  },
  {
    question: '08. Why invest in art with AUREA?',
    paragraphs: [
      `Investing in art with AUREA offers access to an asset class with strong price appreciation, averaging a 12.6% compound annual growth rate since 1995. Art investments have low correlation with traditional markets, providing stability during financial stress. AUREA makes it easy to invest in fractional ownership of art, offering liquidity and diversification, while leveraging blockchain to ensure authenticity and ownership. This combination of financial returns, low risk, and secure digital investment makes art an attractive, innovative option for investors.`
    ]
  },
  {
    question: '09. What are the benefits for investors?',
    list: [
      {
        title: 'Access to High-Value Assets',
        description: 'Investors can diversify their portfolio by investing in contemporary art and luxury goods, both of which have historically strong price appreciation.'
      },
      {
        title: 'Fractional Ownership',
        description: 'AUREA enables fractional ownership, allowing investors to buy shares in high-value assets without needing to purchase the entire item.'
      },
      {
        title: 'Low Correlation',
        description: 'Art and luxury goods have a low correlation to traditional financial markets, offering stability during economic downturns.'
      },
      {
        title: 'Liquidity',
        description: 'Tokenized assets can be easily traded, providing faster access to liquidity.'
      },
      {
        title: 'Secure Investments',
        description: 'Blockchain technology ensures the authenticity, provenance, and ownership of each asset, reducing risks of counterfeiting.'
      },
      {
        title: 'Passive Income',
        description: 'Investors can earn royalties from the resale of tokenized assets over time.'
      }
    ]
  },
  {
    question: '10. What are the benefits for brands?',
    paragraphs: ['The benefits for brands partnering with Aurea include:'],
    list: [
      {
        title: 'Protection Against Counterfeiting',
        description: 'Secure your products with an immutable digital record of authenticity, ensuring customers trust the origin and legitimacy of each item.'
      },
      {
        title: 'Access to New Markets',
        description: 'Enter the digital asset space by tokenizing your luxury goods, attracting tech-savvy consumers and expanding your brand\'s reach.'
      },
      {
        title: 'Fractional Ownership',
        description: 'Offer fractional ownership of high-value products, making them accessible to a wider audience and opening new revenue streams.'
      },
      {
        title: 'Immediate Liquidity',
        description: 'Tokenized goods can be quickly traded, providing faster revenue generation and inventory flexibility.'
      },
      {
        title: 'Royalties',
        description: 'Earn royalties each time a tokenized item is resold, generating continuous passive income for your brand.'
      },
      {
        title: 'Cutting-Edge Innovation',
        description: 'Stay ahead of trends and strengthen your brand by integrating blockchain technology through an easy and intuitive platform.'
      }
    ]
  },
  {
    question: '11. What is Fractional Ownership?',
    paragraphs: [
      `Fractional ownership is a system where multiple investors share ownership of a high-value asset. Instead of purchasing the entire item, investors buy a fraction or percentage of it. This makes expensive assets more accessible and allows individuals to invest in valuable items without needing to cover the full cost. Each owner holds a stake in the asset and can benefit from its appreciation or potential income, such as royalties, if the asset increases in value or is resold or subject to commercial uses.`
    ]
  },
  {
    question: '12. What are Royalties?',
    paragraphs: [
      `Royalties are payments made to the owner or creator of an asset each time it is sold or used, typically in relation to intellectual property or tokenized items. In the context of AUREA, royalties allow artists, brands, or creators to earn a percentage of the resale value whenever their tokenized artwork or luxury goods are sold. Another type of passive income can come from commercial uses of the assets, such as rentals, exhibitions, advertising licenses, etc. This provides a continuous revenue stream, ensuring they benefit from the long-term value of their creations.`
    ]
  },
  {
    question: '13. How do transactions work in AUREA?',
    list: [
      {
        title: 'Step 1',
        description: 'The buyer selects the asset, and payment is held in escrow until the smart contract conditions are met.'
      },
      {
        title: 'Step 2',
        description: 'The asset is securely delivered through specialized logistic agents.'
      },
      {
        title: 'Step 3',
        description: 'Once received and verified, the buyer scans the NFC tag approving the transaction, allowing the escrow to release the funds to the seller and transferring the NFT to the buyer.'
      }
    ]
  }
];

const LearnMore = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleFAQ = index => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <section className="learn-more">
      <div className="faq-section">
        <h2 id="faqs">FAQs</h2>
        <div className="faq">
          {faqs.map((faq, index) => (
            <div key={index} className={`question ${openIndex === index ? 'open' : ''}`} onClick={() => toggleFAQ(index)}>
              <h3>{faq.question} <span className="toggle">{openIndex === index ? '-' : '+'}</span></h3>
              <div className="answer-wrapper" style={{ maxHeight: openIndex === index ? '500px' : '0px', opacity: openIndex === index ? 1 : 0 }}>
                <p>{faq.answer}</p>
                {faq.paragraphs && faq.paragraphs.map((paragraph, index) => (
                  <p key={index}>{paragraph}</p>
                ))}
                {faq.list &&
                  <ul>
                    {faq.list.map((list, index) => (
                      <li>
                        <p key={index}><b>{list.title + ": "}</b>{list.description}</p>
                      </li>
                    ))}
                  </ul>
                }
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="contact-form" id='first-contact'>
        <h2>Get in <br /> <span className="highlight2">Touch</span></h2>
        <ContactForm />
      </div>
    </section>
  );
};

export default LearnMore;