import React, { useRef } from 'react';
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import './Carrousel.css';
import leftArrow from '../../assets/icons/arrow-left.svg';
import rightArrow from '../../assets/icons/arrow-right.svg';
import marketsIcon from '../../assets/icons/markets.svg';
import fractionalIcon from '../../assets/icons/fractional.svg';
import passiveIcon from '../../assets/icons/passive-income.svg';
import protectIcon from '../../assets/icons/protect.svg';


const CarrouselArtists = () => {
  const splideRef = useRef(null);

  const opciones = {
    perMove: 0,
    arrows: false,
    dots: false,
    pagination: false,// Desactiva las flechas internas de Splide
    gap: '50px',
    padding: '32px',
    fixedWidth: '580px',
    fixedHeight: '480px',
    breakpoints: {
      620: {
        fixedWidth: '335px',
        fixedHeight: '450px',
        padding: '12px',
        gap: '12px',
      },
    },
  };

  const handlePrev = () => {
    if (splideRef.current) {
      splideRef.current.go('<');
    }
  };

  const handleNext = () => {
    if (splideRef.current) {
      splideRef.current.go('>');
    }
  };

  return (
    <section className="carrousel-artists">
        <div className="splide__arrows">
          <button className="splide__arrow splide__arrow--prev" onClick={handlePrev}>
            <img src={leftArrow} alt="Anterior" />
          </button>
          <button className="splide__arrow splide__arrow--next" onClick={handleNext}>
            <img src={rightArrow} alt="Siguiente" />
          </button>
        </div>
        <Splide options={opciones} aria-label="Carrusel de tarjetas" ref={splideRef} hasTrack={false}>
          <SplideTrack>
            {/* Tarjeta 1 */}
            <SplideSlide>
              <div className="tarjeta">
                <img src={marketsIcon} alt="" className="icon-tarjeta" />
                <h2>Access New Markets</h2>
                <p>With AUREA, your art isn't just a masterpiece—it becomes a financial asset that can be traded in global markets. By tokenizing your artwork, you can reach a wider audience and tap into new markets that are eager to invest in unique, valuable assets. This transformation opens doors to collectors and investors who view art not just as a cultural treasure, but as a rewarding financial investment.</p>
              </div>
            </SplideSlide>
            {/* Tarjeta 2 */}
            <SplideSlide>
              <div className="tarjeta">
                <img src={fractionalIcon} alt="" className="icon-tarjeta" />
                <h2>Immediate Liquidity through Fractional Ownership</h2>
                <p>One of the key benefits of using AUREA is the ability to offer fractional ownership of your art. This means that your artwork can be divided into shares, allowing multiple investors to own a piece of it. This innovative approach provides immediate liquidity, enabling you to monetize your art quickly while maintaining ownership over a portion of it.</p>
              </div>
            </SplideSlide>
            {/* Tarjeta 3 */}
            <SplideSlide>
              <div className="tarjeta">
                <img src={passiveIcon} alt="" className="icon-tarjeta" />
                <h2>Earn Passive Income from Royalties</h2>
                <p>AUREA ensures that you continue to benefit from your art even after it's sold. Every time your tokenized artwork is resold, you earn royalties. This passive income stream allows you to profit from your art's increasing value over time, ensuring that your creative efforts are continuously rewarded.</p>
              </div>
            </SplideSlide>
            {/* Tarjeta 4 */}
            <SplideSlide>
              <div className="tarjeta">
                <img src={protectIcon} alt="" className="icon-tarjeta" />
                <h2>Easy Protection</h2>
                <p>AUREA makes it easy and intuitive to protect your artwork forever by securely linking each tokenized piece to a digital certificate of authenticity on the blockchain, guiding you step by step, so you can transform your art into a financial asset without needing technical expertise.</p>
              </div>
            </SplideSlide>
          </SplideTrack>
        </Splide>
        {/* Flechas personalizadas */}
    </section>
  );
};

export default CarrouselArtists;
