import React from 'react';
import './SubtituloSeccion.css';

const SubtituloSeccion = ({ texto }) => {
  return (
    <div className="subtitulo">
      <div className="max-w-1200">
        <h2 className="subtitulo-text">{texto}</h2>
      </div>
    </div>
  );
};

export default SubtituloSeccion;