import React, { useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import './SectionTwo.css';
import video from '../../assets/models/iStock-636463142.mp4';
import { useGSAP } from '@gsap/react';
import SplitType from 'split-type'

gsap.registerPlugin(ScrollTrigger);

const SectionTwo = () => {
  const bodyTextRef = useRef(null);

  useGSAP(() => {
    const splitTextAndAnimate = () => {
      // Revertir cualquier split previo antes de volver a dividir
      SplitType.revert(bodyTextRef.current);

      // Volver a dividir el texto
      const text = new SplitType(bodyTextRef.current);
      
      // Aplicar la animación de GSAP
      gsap.fromTo(
        text.lines,
        { filter: 'blur(20px)', opacity: 0 },
        {
          filter: 'blur(0px)',
          opacity: 1,
          duration: 1,
          ease: 'power3.out',
          stagger: 0.1,
          scrollTrigger: {
            trigger: bodyTextRef.current,
            start: 'top 80%',
            end: 'top 10%',
            scrub: true,
          },
        }
      );
    };

    splitTextAndAnimate(); // Ejecuta el split y la animación cuando el componente se monta

    const handleResize = () => {
      splitTextAndAnimate(); // Vuelve a ejecutar cuando la ventana cambia de tamaño
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      SplitType.revert(bodyTextRef.current); // Limpiar split anterior al desmontar
    };
  }, []);


  return (
    <div className="section-two">
      <div className="max-w-1200">
          <h1 className="title-sec-two">THE AUREA SOLUTION</h1>
          <p className="body-text" ref={bodyTextRef}>
          <b>Aurea</b> emerges as the ultimate solution to illuminate the genuine by providing a suite of verification tools combining cutting edge hardware, software and blockchain technology to link physical items to its digital certification of “ownthenticity” in the form <b>of NFTs</b>
          </p>
      </div>
      <video autoPlay muted loop playsInline className="background-vid">
        <source src={video} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default SectionTwo;
