import React from 'react';
import arrowIcon from '../../assets/icons/Vector-up.svg';
import { useRef, useEffect } from 'react';
import './IntroSection.css';


const IntroSection = ({ 
  title = "LEARN MORE ABOUT AUREA",
  introText = "Explore the depths of Aurea's vision and technology through our comprehensive white paper. Delve into the intricacies of our platform, learn about our innovative approach to asset authentication, and discover how Aurea is revolutionizing the concept of ownership in the digital age.",
  buttonText = "LEARN MORE ABOUT AUREA"
}) => {
  const marqueeRef = useRef(null);

  useEffect(() => {
    const marquee = marqueeRef.current;
    if (!marquee) {
      console.error('El elemento marquee no se encontró');
      return;
    }

    const span = marquee.querySelector('.marquee span');
    if (!span) {
      console.error('El elemento .marquee span no se encontró');
      return;
    }

    const content = span.textContent;
    span.textContent = content + ' ' + content;
    const width = span.offsetWidth;
    const animationDuration = width / 100;
    span.style.setProperty('--animation-duration', `${animationDuration}s`);
  }, [title]);

  return (
    <section className="intro-section">
      <div className="marquee-wrapper">
        <div className="marquee" ref={marqueeRef}>
          <span>
            {title} • {title} • {title} • {title} • {title} • 
          </span>
        </div>
      </div>
      <div className="intro-paragraph">
        <p className="intro-text">{introText}</p>
        <button className="learn-more-button">
          {buttonText} <img src={arrowIcon} alt="Arrow" />
        </button>
      </div>
      <div className='spacer-30'></div>
    </section>
  );
};

export default IntroSection;