import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Artists from './pages/Artists';
import Header from './sections/Header';
import Footer from './sections/Footer';
import Home from './pages/Home';
import '../styles/App.css'
import Brands from './pages/Brands';
import Investors from './pages/Investors';
import ScrollToTop from './scrollToTop.js';

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <ScrollToTop />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/artists" element={<Artists />} />
            <Route path="/brands" element={<Brands />} />
            <Route path="/investors" element={<Investors />} />
          </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;