import React from 'react';
import './BulletPoints.css';

const BulletPoints = ({ items, video }) => {
  return (
    <div className="bullet-container">
      <div className="max-w-1200">
        <div className="video-bullet-component">
          <video autoPlay muted playsInline>
            <source src={video} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        <ul className="bullet-list-component">
          {items.map((item, index) => (
            <li key={index} className="bullet-item">{item}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default BulletPoints;