import React, { useRef } from 'react';
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import './Carrousel.css';
import leftArrow from '../../assets/icons/arrow-left.svg';
import rightArrow from '../../assets/icons/arrow-right.svg';
import marketsIcon from '../../assets/icons/markets.svg';
import fractionalIcon from '../../assets/icons/fractional.svg';
import passiveIcon from '../../assets/icons/passive-income.svg';
import protectIcon from '../../assets/icons/protect.svg';
import intuitiveIcon from '../../assets/icons/intuitiveIcon.svg'


const CarrouselBrands = () => {
  const splideRef = useRef(null);

  const opciones = {
    perMove: 0,
    arrows: false,
    dots: false,
    pagination: false,// Desactiva las flechas internas de Splide
    gap: '50px',
    padding: '32px',
    fixedWidth: '580px',
    fixedHeight: '480px',
    breakpoints: {
      620: {
        fixedWidth: '335px',
        fixedHeight: '450px',
        padding: '12px',
        gap: '12px',
      },
    },
  };

  const handlePrev = () => {
    if (splideRef.current) {
      splideRef.current.go('<');
    }
  };

  const handleNext = () => {
    if (splideRef.current) {
      splideRef.current.go('>');
    }
  };

  return (
    <section className="carrousel-artists">
        <div className="splide__arrows">
          <button className="splide__arrow splide__arrow--prev" onClick={handlePrev}>
            <img src={leftArrow} alt="Anterior" />
          </button>
          <button className="splide__arrow splide__arrow--next" onClick={handleNext}>
            <img src={rightArrow} alt="Siguiente" />
          </button>
        </div>
        <Splide options={opciones} aria-label="Carrusel de tarjetas" ref={splideRef} hasTrack={false}>
          <SplideTrack>
            {/* Tarjeta 1 */}
            <SplideSlide>
              <div className="tarjeta">
                <img src={marketsIcon} alt="" className="icon-tarjeta" />
                <h2>Expand Your Market with Digital Ownership</h2>
                <p>By tokenizing your luxury goods, AUREA enables your brand to enter the flourishing digital asset market. This not only enhances the value of your products by linking them to exclusive digital assets but also allows you to tap into a new generation of tech-savvy consumers who are eager to invest in luxury items in innovative ways.</p>
              </div>
            </SplideSlide>
            {/* Tarjeta 2 */}
            <SplideSlide>
              <div className="tarjeta">
                <img src={fractionalIcon} alt="" className="icon-tarjeta" />
                <h2>Offer Fractional Ownership</h2>
                <p>With AUREA, your brand can offer fractional ownership of high-value luxury items, making them more accessible to a broader audience. This opens up new revenue streams and allows collectors and enthusiasts to invest in luxury goods without needing to purchase the entire item.</p>
              </div>
            </SplideSlide>
            {/* Tarjeta 3 */}
            <SplideSlide>
              <div className="tarjeta">
                <img src={protectIcon} alt="" className="icon-tarjeta" />
                <h2>Immediate Access to Liquidity</h2>
                <p>Tokenizing your luxury products through AUREA provides immediate access to liquidity. Whether you're selling a limited-edition item or a high-value collectible, the tokenized asset can be traded on various platforms, providing your brand with quicker revenue generation. This also offers flexibility in managing your inventory and capital.</p>
              </div>
            </SplideSlide>
            {/* Tarjeta 4 */}
            <SplideSlide>
              <div className="tarjeta">
                <img src={passiveIcon} alt="" className="icon-tarjeta" />
                <h2>Generate Passive Income from Royalties</h2>
                <p>With AUREA, every time a tokenized item is resold, your brand earns a royalty. This ensures that your brand continues to benefit from the increasing value of your products over time, turning each luxury item into a source of ongoing revenue.</p>
              </div>
            </SplideSlide>
            {/* Tarjeta 5 */}
            <SplideSlide>
              <div className="tarjeta">
                <img src={intuitiveIcon} alt="" className="icon-tarjeta" />
                <h2>Seamless and Intuitive Tokenization Process</h2>
                <p>AUREA makes it easy to integrate blockchain technology into your business. Our platform is designed to be user-friendly, with an intuitive interface that simplifies the process of tokenizing your products. Whether you're new to NFTs or a seasoned pro, AUREA offers the support and tools needed to get started quickly and efficiently.</p>
              </div>
            </SplideSlide>
          </SplideTrack>
        </Splide>
        {/* Flechas personalizadas */}
    </section>
  );
};

export default CarrouselBrands;
