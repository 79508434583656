import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './SectionThree.css';
import topVideo from '../../assets/models/PulseWire.mp4';

import ctaImage1 from '../../assets/images/venus-3d-cut.png';
import ctaImage2 from '../../assets/images/btc-3d.png';
import ctaImage3 from '../../assets/images/watch-3d.png';

import arrowIcon from '../../assets/icons/Vector-up.svg'; // Add the new arrow icon

const SectionThree = () => {
  const [hoveredButton, setHoveredButton] = useState(null);


  const getCTAImage = () => {
    switch (hoveredButton) {
      case 'auth':
        return ctaImage1;
      case 'invest':
        return ctaImage2;
      case 'protect':
        return ctaImage3;
      default:
        return ctaImage1;
    }
  };

  return (
    <div className="section-three">
      <div className="top-container">
        <div className="video-container-three">
          <video autoPlay muted loop playsInline>
            <source src={topVideo} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        <div className="text-container-three">
          <h1 className="section-title-three">The <span className="outlined-text">Marketplace</span><br></br> of Trust</h1>
          <p className="section-body-three">
            By empowering your assets with <b>Aurea</b> your collection
            unlocks <b>new dimensions of interaction, investment and innovation</b> in a
            marketplace where provenance is assured and
            possibilities are limitless.
          </p>
          <ul className='bullet-list'>
            <li className='bullet-item'>Get access to DeFi mechanism to enhance liquidity</li>
            <li className='bullet-item'>Invest in high value assets through fractional ownership</li>
            <li className='bullet-item'>Ensure equity from royalties making each creation a permanent source of income</li>
          </ul>
        </div>
      </div>
      <div className='h-50'></div>
      <div className="intro-text-three">
        <h1 className="join-us-title" id='join-us'>Join Us</h1>
        <p><b>Join us as we lead the way to a future where every treasure tells its true story.</b></p>
        <p> Be Part of Something Monumental</p>
      </div>
      <div className="bottom-container">
        <div className="cta-buttons-three">
          <Link to="/investors" className="cta-button" onMouseEnter={() => setHoveredButton('invest')}>
            <div className='m-w-530'>
              <p>BECOME AN EARLY INVESTOR</p>
              <img src={arrowIcon} alt="Arrow" />
            </div>
          </Link>
          <div className="separator"></div>
          <Link to="/artists" className="cta-button" onMouseEnter={() => setHoveredButton('auth')} preventScrollReset={true}>
            <div className='m-w-530'>
              <p>AUTHENTICATE YOUR ART</p>
              <img src={arrowIcon} alt="Arrow" />
            </div>
          </Link>
          <div className="separator"></div>
          <Link to="/brands" className="cta-button" onMouseEnter={() => setHoveredButton('protect')}>
            <div className='m-w-530'>
              <p>PROTECT YOUR BRAND</p>
              <img src={arrowIcon} alt="Arrow" />
            </div>
          </Link>
          <div className="separator"></div>
        </div>
        <div className="cta-image-three">
          <img src={getCTAImage()} alt="CTA" />
        </div>
      </div>
    </div>
  );
};

export default SectionThree;
