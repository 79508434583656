import React from 'react';
import './IntroForm.css';

const IntroForm = ({ title1, title2, backgroundImage }) => {
  return (
    <section className="intro-form">
      <div className='max-w-1200'>
        <div className="intro-form-image-container">
          <img src={backgroundImage} alt="Background" className="intro-form-image" />
        </div>
        <div className="intro-form-content">
          <h1 className="intro-form-title">{title1}</h1>
          <h1 className="intro-form-title">{title2}</h1>
        </div>
      </div>
    </section>
  );
};

export default IntroForm;
