import backgroundImageInvestors from '../../assets/images/btc-3d.png';
import IntroForm from '../sections/IntroForm';
import ParrafoNormal from '../text-components/parrafoNormal';
import SubtituloSeccion from '../text-components/SubtituloSeccion';
import ContactFormArtists from '../forms/emailFormArtist';
import IntroSection from '../sections/IntroSection';
import CarrouselInvestors from '../sections/CarruselInvestors';

function Investors() {

  return (
    <main>
      <IntroForm title1="BECOME" title2="AN INVESTOR" backgroundImage={backgroundImageInvestors} />
      <div className="black-background">
        <div className="spacer-60"></div>
        <SubtituloSeccion texto="Why AUREA" />
        <div className="spacer-30"></div>
        <ParrafoNormal texto="Traditional methods of verification are outdated and prone to fraud." />
        <div className="spacer-30"></div>
        <ParrafoNormal texto="Every year, billions are lost to counterfeit products, impacting not only businesses but also consumers. These markets, such as art and luxury, represent a combined market value of around €900 billion annually." />
        <div className="spacer-30"></div>
        <ParrafoNormal texto="AUREA is poised to provide the final solution to this problem, redefining the way authenticity and ownership are managed." />
        <div className="spacer-30"></div>
        <CarrouselInvestors />
        <div className="spacer-60"></div>
        <SubtituloSeccion texto="Invest in the Future with AUREA" />
        <div className="spacer-30"></div>
        <ParrafoNormal texto="Investing in AUREA means investing in the future of authenticity, security, and ownership for high-value goods. With a growing market, cutting-edge technology, and a unique revenue model, AUREA is your gateway to capitalizing on the shift toward digital ownership and authenticity. Join us in revolutionizing how the world values and protects its most prized possessions." />
        <div className="spacer-60"></div>
      </div>
        <ContactFormArtists />
        <IntroSection />
    </main>
  );
}

export default Investors;