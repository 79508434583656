import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import arrowIcon from '../../assets/icons/Vector-up.svg';
import './ContactForm.css';

const ContactFormArtists = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    role: '',
    art_details: '',
    web_link: '',
    social: '',
    message: '',
  });
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const newErrors = {};
    if (!formData.name) newErrors.name = 'Name is required';
    if (!formData.email) newErrors.email = 'Email is required';
    if (formData.email && !validateEmail(formData.email)) newErrors.email = 'Invalid email address';
    if (!formData.phone) newErrors.phone = 'Phone is required';
    if (!formData.role) newErrors.role = 'Role is required';
    if (!formData.message) newErrors.message = 'Message is required';

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    emailjs.send(
      process.env.REACT_APP_EMAILJS_SERVICE_ID,
      process.env.REACT_APP_EMAILJS_ARTIST_TEMPLATE_ID,
      formData,
      process.env.REACT_APP_EMAILJS_USER_ID
    ).then((response) => {
      console.log('SUCCESS!', response.status, response.text);
    }).catch((err) => {
      console.error('FAILED...', err);
    });
  };

  return (
    <section className='contact-form-artists'>
      <div className="max-w-1200">
        <div className="container-form" id='first-contact'>
          <form onSubmit={handleSubmit} className="contact-form-card">
            <div className="input-group">
              <input
                type="text"
                name="name"
                placeholder="NAME*"
                value={formData.name}
                onChange={handleChange}
                required
              />
              {errors.name && <span className="error">{errors.name}</span>}
            </div>
            <div className="input-group">
              <input
                type="email"
                name="email"
                placeholder="EMAIL*"
                value={formData.email}
                onChange={handleChange}
                required
              />
              {errors.email && <span className="error">{errors.email}</span>}
            </div>
            <div className="input-group">
              <input
                type="tel"
                name="phone"
                placeholder="PHONE*"
                value={formData.phone}
                onChange={handleChange}
                required
              />
              {errors.phone && <span className="error">{errors.phone}</span>}
            </div>
            <div className="input-group">
              <div className="radio-group">
                <label>
                  <input
                    type="radio"
                    name="role"
                    value="artist"
                    checked={formData.role === "artist"}
                    onChange={handleChange}
                    required
                  />
                  Artist
                </label>
                <label>
                  <input
                    type="radio"
                    name="role"
                    value="owner"
                    checked={formData.role === "owner"}
                    onChange={handleChange}
                    required
                  />
                  Owner
                </label>
                <label>
                  <input
                    type="radio"
                    name="role"
                    value="both"
                    checked={formData.role === "both"}
                    onChange={handleChange}
                    required
                  />
                  Both
                </label>
              </div>
              {errors.role && <span className="error">{errors.role}</span>}
            </div>
            <div className="input-group">
              <textarea
                name="art_details"
                placeholder="ART DETAILS"
                value={formData.art_details}
                onChange={handleChange}
              />
            </div>
            <div className="input-group">
              <input
                type="url"
                name="web_link"
                placeholder="WEB LINK"
                value={formData.web_link}
                onChange={handleChange}
              />
            </div>
            <div className="input-group">
              <input
                type="text"
                name="social"
                placeholder="SOCIAL MEDIA"
                value={formData.social}
                onChange={handleChange}
              />
            </div>
            <div className="input-group">
              <textarea
                name="message"
                placeholder="MESSAGE*"
                value={formData.message}
                onChange={handleChange}
                required
              />
              {errors.message && <span className="error">{errors.message}</span>}
            </div>
            <button type="submit" className="submit-button">
              Submit <img src={arrowIcon} alt="Arrow" className="arrow-icon" />
            </button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default ContactFormArtists;