import backgroundImageBrands from '../../assets/images/watch-3d.png';
import IntroForm from '../sections/IntroForm';
import ParrafoNormal from '../text-components/parrafoNormal';
import SubtituloSeccion from '../text-components/SubtituloSeccion';
import ContactFormArtists from '../forms/emailFormArtist';
import IntroSection from '../sections/IntroSection';
import CarrouselBrands from '../sections/CarruselBrands';

function Brands() {

  return (
    <main>
      <IntroForm title1="PROTECT" title2="YOUR BRAND" backgroundImage={backgroundImageBrands} />
      <div className="black-background">
        <div className="spacer-60"></div>
        <SubtituloSeccion texto="Authenticate and Protect Your Brand" />
        <div className="spacer-30"></div>
        <ParrafoNormal texto="In the luxury market, authenticity is everything. AUREA empowers luxury brands to protect their identity by providing a secure, immutable digital record of authenticity and ownership. Each product can be tokenized into a Non-Fungible Token (NFT), ensuring that your customers can trust the provenance and legitimacy of every item they purchase." />
        <div className="spacer-30"></div>
        <CarrouselBrands />
        <div className="spacer-60"></div>
        <SubtituloSeccion texto="Expand Your Market with Digital Ownership" />
        <div className="spacer-30"></div>
        <ParrafoNormal texto="By tokenizing your luxury goods, AUREA enables your brand to enter the flourishing digital asset market. This not only enhances the value of your products by linking them to exclusive digital assets but also allows you to tap into a new generation of tech-savvy consumers who are eager to invest in luxury items in innovative ways." />
        <div className="spacer-30"></div>
      </div>
        <ContactFormArtists />
        <IntroSection />
    </main>
  );
}

export default Brands;