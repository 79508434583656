import React, { useRef } from 'react';
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import './Carrousel.css';
import leftArrow from '../../assets/icons/arrow-left.svg';
import rightArrow from '../../assets/icons/arrow-right.svg';
import marketsIcon from '../../assets/icons/markets.svg';
import fractionalIcon from '../../assets/icons/fractional.svg';
import passiveIcon from '../../assets/icons/passive-income.svg';
import globalIcon from '../../assets/icons/globalIcon.svg';
import blockchainIcon from '../../assets/icons/blockchain.svg';


const CarrouselInvestors = () => {
  const splideRef = useRef(null);

  const opciones = {
    perMove: 0,
    arrows: false,
    dots: false,
    pagination: false,// Desactiva las flechas internas de Splide
    gap: '50px',
    padding: '32px',
    fixedWidth: '580px',
    fixedHeight: '480px',
    breakpoints: {
      620: {
        fixedWidth: '335px',
        fixedHeight: '450px',
        padding: '12px',
        gap: '12px',
      },
    },
  };

  const handlePrev = () => {
    if (splideRef.current) {
      splideRef.current.go('<');
    }
  };

  const handleNext = () => {
    if (splideRef.current) {
      splideRef.current.go('>');
    }
  };

  return (
    <section className="carrousel-artists">
        <div className="splide__arrows">
          <button className="splide__arrow splide__arrow--prev" onClick={handlePrev}>
            <img src={leftArrow} alt="Anterior" />
          </button>
          <button className="splide__arrow splide__arrow--next" onClick={handleNext}>
            <img src={rightArrow} alt="Siguiente" />
          </button>
        </div>
        <Splide options={opciones} aria-label="Carrusel de tarjetas" ref={splideRef} hasTrack={false}>
          <SplideTrack>
            {/* Tarjeta 1 */}
            <SplideSlide>
              <div className="tarjeta">
                <img src={marketsIcon} alt="" className="icon-tarjeta" />
                <h2>Growing Market Demand</h2>
                <p>AUREA taps into the expanding demand for authenticity in high-value markets such as luxury, art, collectibles, technology items or NBA jerseys.... As these markets continue to grow, so does the need for solutions that ensure trust and transparency.</p>
              </div>
            </SplideSlide>
            {/* Tarjeta 2 */}
            <SplideSlide>
              <div className="tarjeta">
                <img src={blockchainIcon} alt="" className="icon-tarjeta" />
                <h2>Scalable Blockchain Technology</h2>
                <p>AUREA’s platform is built on blockchain, offering secure, transparent, and scalable solutions. This gives investors confidence in the longevity and adaptability of the technology to diverse markets beyond luxury and art.</p>
              </div>
            </SplideSlide>
            {/* Tarjeta 3 */}
            <SplideSlide>
              <div className="tarjeta">
                <img src={fractionalIcon} alt="" className="icon-tarjeta" />
                <h2>Revenue from Fractional Ownership</h2>
                <p>Through fractional ownership, AUREA enables the tokenization of valuable items, making it easier to invest and generate income. Investors can benefit from a broader range of assets that can be divided and sold in smaller portions.</p>
              </div>
            </SplideSlide>
            {/* Tarjeta 4 */}
            <SplideSlide>
              <div className="tarjeta">
                <img src={passiveIcon} alt="" className="icon-tarjeta" />
                <h2>Continued Royalties</h2>
                <p>Each tokenized item on AUREA earns royalties on every resale, fractionalized or licensed, offering an additional revenue stream for investors. This ensures that long-term value appreciation is captured even after the initial sale.</p>
              </div>
            </SplideSlide>
            {/* Tarjeta 5 */}
            <SplideSlide>
              <div className="tarjeta">
                <img src={globalIcon} alt="" className="icon-tarjeta" />
                <h2>Global Reach and Market Expansion</h2>
                <p>AUREA provides access to global markets by tokenizing items and making them digitally tradable. This expands the market reach for high-value items and gives investors exposure to a wider pool of potential buyers.</p>
              </div>
            </SplideSlide>
          </SplideTrack>
        </Splide>
        {/* Flechas personalizadas */}
    </section>
  );
};

export default CarrouselInvestors;
