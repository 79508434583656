import React, { useRef, useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import vectorIcon from '../../assets/icons/Vector.svg';
import venus3d from '../../assets/images/hero2.png';
import './Hero.css';

gsap.registerPlugin(ScrollTrigger);

const Hero = () => {
    const textRef = useRef();
    const imgRef = useRef();

    useEffect(() => {
        const img = imgRef.current;

        gsap.to(img.current, {
            scrollTrigger: {
                trigger: '.hero',
                start: 'top top',
                end: 'bottom 10%',
                scrub: true,
            },
            x: '-100vw', // Move the video to the left off the screen
            opacity: 0, // Fade out the video
        });
    }, []);

    return (
        <section className="hero">
            <div className="max-w-1200">
                <div className="video-wrapper">
                    <img ref={imgRef} src={venus3d} className="hero-video" alt="Venus 3D model" />
                </div>
                <div className="textContainer" ref={textRef}>
                    <h1 className="header-text">AUREA IS FOR REAL</h1>
                    <span className="white-high">WORLD ASSETS</span>
                    <div className="subtitle-container">
                        <p className="subtitle-text">The ultimate solution to authenticate your </p>
                        <span className="rw-words rw-words-1 keyword-text">
                            <span className="current" data-bg-color="#ffc703" data-color="#000"> art </span>
                            <span className="next" data-bg-color="#ffc703" data-color="#000"> possession </span>
                            <span data-bg-color="#ffc703" data-color="#000"> treasure </span>
                            <span data-bg-color="#ffc703" data-color="#000">craft</span>
                        </span>
                    </div>

                </div>
                <button onClick={() => { document.querySelector('#section-one').scrollIntoView({ behavior: 'smooth' }) }} className="scroll-button">
                    Scroll down
                    <img src={vectorIcon} alt="Scroll down arrow" className="vector-icon" />
                </button>
            </div>
        </section>
    );
};

export default Hero;
